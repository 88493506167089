/* survey js style overrides*/
:root {
    --sjs-font-family: Roboto, sans-serif !important;
    --secondary: #ffd984;
    --secondary-back-light: #fefbde3b;
    --primary: #0a3a5b;
    --foreground: var(--primary);
    --sjs-general-dim-forecolor: var(--primary);
    --sjs-primary-backcolor-dark: var(--primary);
    --foreground-light: #afaeb1;
}

.svc-tab-designer,
.sv-components-row {
    background-color: #f7f7f7;
}

.svc-btn {
    background-color: #fff;
}

.svc-btn span {
    color: #0a3a5b;
    transition: 0.5s linear;
}

.svc-btn:hover {
    background-color: #0a3a5b !important;

    transition: 0.5s linear;
}

.svc-btn:hover > span {
    color: white;
}

.spg-panel__title,
.spg-question__title {
    font-size: 0.875rem !important;
    color: #616161 !important;
}

.sv-svg-icon use {
    fill: #afaeb1;
    color: #afaeb1;
}

.svc-toolbox__item:hover use {
    color: #0a3a5b;
}

#cameraContainer:hover #camera {
    opacity: 1 !important;
    transition: 0.3s linear;
}

#camera {
    font-size: 80px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    cursor: pointer;
    transition: 0.3s linear;
}

#cameraCapture {
    width: 100%;
    height: 100%;
}

#cameraContainer {
    width: 100%;
    min-height: 150px;
    position: relative;
}

.svc-creator__area.svc-creator__area--with-banner {
    height: 100% !important;
}

.svc-creator__banner {
    display: none !important;
}
